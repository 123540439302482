import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import CommentsContext from '@/comments/context/CommentsContext';
import { COMMENTS_FILTER_OPTIONS } from '@/comments/constants/Constants';

const propTypes = {
  modelId: PropTypes.string.isRequired,
  modelType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
const defaultProps = {};

function CommentsProvider({ modelId, modelType, children }) {
  // States
  const [filter, setFilter] = useState(COMMENTS_FILTER_OPTIONS.open);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [isNewGeneralCommentActive, setIsNewGeneralCommentActive] = useState(false);

  const history = useHistory();
  const urlQueryParams = new URLSearchParams(window.location.search);
  const hashUrlQueryParams = new URLSearchParams(history.location.search);
  const isSyncUrlQueryParams = urlQueryParams.has('comment') && !hashUrlQueryParams.has('comment');
  const [isInitialized, setIsInitialized] = useState(!isSyncUrlQueryParams);
  const providerValue = useMemo(
    () => ({
      modelId,
      modelType,
      filter,
      setFilter,
      selectedCommentId,
      setSelectedCommentId,
      isNewGeneralCommentActive,
      setIsNewGeneralCommentActive,
    }),
    [
      modelId,
      modelType,
      filter,
      setFilter,
      selectedCommentId,
      setSelectedCommentId,
      isNewGeneralCommentActive,
      setIsNewGeneralCommentActive,
    ],
  );

  // If a page uses a hash router we need to modify the URL and move the query parameters to the hash router.
  function checkQueryParams() {
    if (isSyncUrlQueryParams) {
      const commentId = urlQueryParams.get('comment');

      removeCommentParam();
      addCommentParamToHashRouter(commentId);
    }
  }

  function removeCommentParam() {
    urlQueryParams.delete('comment');
    const urlQueryParamsString = urlQueryParams.toString() ? `?${urlQueryParams}` : '';
    window.history.replaceState(null, '', window.location.pathname + urlQueryParamsString);
  }

  function addCommentParamToHashRouter(commentId) {
    hashUrlQueryParams.set('comment', commentId);
    history.replace({
      search: hashUrlQueryParams.toString(),
    });
  }

  useEffect(() => {
    if (!isInitialized) {
      checkQueryParams();
      setIsInitialized(true);
    }
  }, []);

  if (!isInitialized) {
    return null;
  }

  return <CommentsContext.Provider value={providerValue}>{children}</CommentsContext.Provider>;
}

CommentsProvider.propTypes = propTypes;
CommentsProvider.defaultProps = defaultProps;

export default CommentsProvider;
