import PropTypes from 'prop-types';
import { useMemo } from 'react';
import ModelContext from '@/storychief/context/ModelContext';

const propTypes = {
  children: PropTypes.node.isRequired,
  modelId: PropTypes.string.isRequired,
  modelType: PropTypes.string.isRequired,
};
const defaultProps = {};

function ModelProvider({ children, modelId, modelType }) {
  const providerValue = useMemo(
    () => ({
      modelId,
      modelType,
    }),
    [modelId, modelType],
  );

  return <ModelContext.Provider value={providerValue}>{children}</ModelContext.Provider>;
}

ModelProvider.propTypes = propTypes;
ModelProvider.defaultProps = defaultProps;
export default ModelProvider;
