// Statuses
export const COMMENT_VISIBILITY_INTERNAL = 1;
export const COMMENT_VISIBILITY_PUBLIC = 2;

export const COMMENT_EVENTS = {
  create: 'create',
};

/**
 * @enum {number}
 */
export const COMMENTS_FILTER_OPTIONS = {
  all: -1,
  open: 1,
  resolved: 2,
  yourThreadsAndMentions: 3,
};
